/* eslint-disable consistent-return */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { useWagmiAuth } from 'app/providers';
import BigNumber from 'bignumber.js';
import { userModel } from 'entities/user';
import { calculateTierBonus } from 'pages/home/ui/crowdsale/lib';
import {
  accurateToFixed,
  checkIsAddress,
  LAUNCHPADS_MAP,
  toDecimals,
  useShallowSelector,
  useValidateInputField,
  ValidationTypes,
} from 'shared';

import { useBuyForm } from './useBuyForm';

/* 0.75$ */
const CRAT_PRICE = 0.75;

const INPUT_VALIDATION = {
  initialState: '',
  type: ValidationTypes.number,
  minValue: 0,
  decimals: 6,
};

export const useBuyCoins = () => {
  const [value, onChangeStableAmount, onChangeStableAmountOrigin] = useValidateInputField(INPUT_VALIDATION);
  const [launchpad, setLaunchpad] = useState<(typeof LAUNCHPADS_MAP)[keyof typeof LAUNCHPADS_MAP]>(
    LAUNCHPADS_MAP.GemPad,
  );
  const { state: authState } = useWagmiAuth();
  const params = useParams();

  const referralFather = useShallowSelector(userModel.selectors.getProp('referralFather'));
  const tier = useShallowSelector(userModel.selectors.getProp('tier'));
  const totalSpend = useShallowSelector(userModel.selectors.getProp('totalSpend'));

  const userReferral = checkIsAddress(referralFather) && referralFather !== authState.address && referralFather;

  const {
    control,
    setValue,
    formState: { errors, isValid, isDirty },
    register,
    handleSubmit,
  } = useBuyForm();

  const receiveAmount = useMemo(() => {
    if (!value.length) {
      return '0';
    }

    const decimalValue = toDecimals(value);
    const totalValue = new BigNumber(decimalValue).plus(totalSpend);
    const nextTierBonus = calculateTierBonus(totalValue.toFixed(), CRAT_PRICE.toString(), Number(tier));

    return accurateToFixed(
      new BigNumber(decimalValue).dividedBy(toDecimals(CRAT_PRICE)).plus(nextTierBonus).toNumber(),
      6,
    );
  }, [value, totalSpend, tier]);

  const amountError = errors.stablesAmount?.message;
  const referralError = errors.fatherAddress?.message;
  const errorMessage = amountError || referralError;

  useEffect(() => {
    const linkReferral =
      authState.isAuthorized && checkIsAddress(params.address || '') && params.address !== authState.address
        ? (params.address as string)
        : '';

    setValue('fatherAddress', userReferral || linkReferral);
  }, [userReferral, setValue, authState.isAuthorized, params.address, authState.address]);

  const onLaunchpadChange = useCallback((event: SelectChangeEvent<any>) => {
    setLaunchpad(LAUNCHPADS_MAP[event.target.value]);
  }, []);

  const onReferralAddressChange = useCallback(
    (e: any) => {
      const match = /0x[a-fA-F0-9]{40}$/g.exec(e.target.value);

      if (match) {
        setValue('fatherAddress', match[0]);
      }
    },
    [setValue],
  );

  return useMemo(
    () => ({
      isAuthenticated: authState.isAuthorized,
      receiveAmount,
      userReferral,
      handleSubmit: handleSubmit(() => window.open(launchpad.url)),

      form: {
        onReferralAddressChange,
        register,
        control,
        isValid,
        isDirty,
        errorMessage,
        errors: {
          amount: amountError,
          referral: referralError,
        },
      },

      launchpadField: {
        onLaunchpadChange,
        launchpad,
      },

      validateField: {
        value,
        onChangeStableAmount,
        onChangeStableAmountOrigin,
      },
    }),
    [
      amountError,
      authState.isAuthorized,
      control,
      errorMessage,
      handleSubmit,
      isDirty,
      isValid,
      launchpad,
      onChangeStableAmount,
      onChangeStableAmountOrigin,
      onLaunchpadChange,
      onReferralAddressChange,
      receiveAmount,
      referralError,
      register,
      userReferral,
      value,
    ],
  );
};
