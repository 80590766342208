import { useLocation } from 'react-router-dom';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { appRoutes, camelize, COLOR_ACCENT, COLOR_BG_BLACK_2, extractRootPath } from 'shared';
import { socials } from 'widgets/layout/constants';

export const Community = () => {
  const { pathname } = useLocation();
  const isPreIeoPrivatePlacementPath =
    appRoutes[camelize(extractRootPath(pathname))]?.dirName === 'pre-ieo-private-placement';

  const communityTitle = isPreIeoPrivatePlacementPath
    ? 'Join the Future of Decentralized Commerce!'
    : 'Meet the worldwide community';

  const communityDescription = isPreIeoPrivatePlacementPath
    ? 'Don’t miss this once-in-a-lifetime opportunity to be part of a blockchain revolution. Secure your $CRAT coins today and step into the future with CratD2C.'
    : 'Join a fast-growing community of developers and innovators connected all over the world, building the new era of the internet.';

  return (
    <Box mb={9}>
      <Typography component="span" variant="h1">
        {communityTitle}
      </Typography>
      <Grid container justifyContent="end">
        <Grid item xs={12} md={8} mt={{ xs: 3, md: 6 }}>
          <Typography variant="h5" fontSize={{ xs: 16, md: 24 }} maxWidth={isPreIeoPrivatePlacementPath ? 728 : 600}>
            {communityDescription}
          </Typography>
        </Grid>
        <Grid item container xs={12} md={8} spacing={2} mt={{ xs: 4, md: 6 }}>
          {socials.map(({ Icon, url, title, description }) => {
            return (
              <Grid item xs={12} lg={6} key={url}>
                <Card
                  sx={{
                    minHeight: '120px',
                    padding: '24px 20px 18px 24px',
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    <Stack
                      component="a"
                      target="_blank"
                      rel="noreferrer"
                      href={url}
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        flexShrink: 0,
                        width: 48,
                        height: 48,
                        borderRadius: '50%',
                        backgroundColor: COLOR_ACCENT,
                      }}
                    >
                      <Icon
                        sx={{
                          color: COLOR_BG_BLACK_2,
                        }}
                      />
                    </Stack>
                    <Box
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      <Typography mb={0.5} variant="h5" fontWeight={600}>
                        {title}
                      </Typography>
                      <Typography variant="body2" className="secondary font-secondary">
                        {description}
                      </Typography>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};
