import React from 'react';
import { Box, Divider, MenuItem, SelectProps, Stack, TextFieldProps, Typography } from '@mui/material';
import { InputWithSelect, LAUNCHPADS, LAUNCHPADS_MAP, useBreakpoints } from 'shared';

const MENU_ITEM_SX = {
  alignItems: 'center',
};

const renderOptions = () => {
  return LAUNCHPADS.map((l) => (
    <MenuItem sx={MENU_ITEM_SX} value={l.title} key={l.title}>
      <Box component="img" width={20} height={20} src={l.icon} sx={{ mr: 1 }} />
      {l.title}
    </MenuItem>
  ));
};

type BuyInputProps = {
  onLaunchpadChange: SelectProps['onChange'];
  launchpad: (typeof LAUNCHPADS_MAP)[keyof typeof LAUNCHPADS_MAP];
  isError?: boolean;
} & TextFieldProps;

export const BuyInput = React.forwardRef<any, BuyInputProps>(
  ({ onLaunchpadChange, isError, launchpad, ...inputProps }, ref) => {
    const { isDownSmLayout } = useBreakpoints();

    return (
      <InputWithSelect
        selectProps={{
          onChange: onLaunchpadChange,
          value: launchpad.title,
          options: renderOptions(),
          renderValue: (val) => {
            const found = LAUNCHPADS_MAP[val as keyof typeof LAUNCHPADS_MAP];

            if (!found) {
              return null;
            }

            return (
              <Stack direction="row" sx={MENU_ITEM_SX}>
                <Box
                  component="img"
                  width={20}
                  height={20}
                  src={found.icon}
                  sx={{
                    mr: 1,
                  }}
                />
                {isDownSmLayout ? null : found.title}
              </Stack>
            );
          },
          before: (
            <>
              <Typography>$</Typography>
              <Divider orientation="vertical" sx={{ height: 'auto' }} />
            </>
          ),
        }}
        inputProps={{
          ...inputProps,
          ref,
          placeholder: '0.00',
          color: isError ? 'error' : 'primary',
        }}
      />
    );
  },
);
