import { useCallback, useEffect } from 'react';
import { CHAINS } from 'app/config/wallet';
import { useWagmiAuth } from 'app/providers';
import { crowdsaleModel } from 'entities/crowdsale';
import { userModel } from 'entities/user';
import { useWalletClient, useWatchContractEvent } from 'wagmi';

import { CROWDSALE_ABI, wagmiContracts } from '../../config';
import { useAppDispatch } from '../useAppDispatch';
import { useIntervalAsync } from '../useIntervalAsync';

export const useUpdateCrowdsaleData = () => {
  const dispatch = useAppDispatch();

  const { isSuccess: isWalletConnected } = useWalletClient();

  const { state: authState } = useWagmiAuth();

  const getCrowdsaleData = useCallback(async () => {
    dispatch(crowdsaleModel.thunks.getCrowdsaleData());
  }, [dispatch]);

  useIntervalAsync(getCrowdsaleData, 20_000);

  useEffect(() => {
    if (isWalletConnected && authState.isAuthorized) {
      dispatch(userModel.thunks.getUserInfo());
    }
  }, [dispatch, authState.isAuthorized, isWalletConnected]);

  useWatchContractEvent({
    abi: CROWDSALE_ABI,
    chainId: CHAINS[0].id,
    onLogs: (logs) => {
      if (!logs.length) {
        return;
      }

      dispatch(crowdsaleModel.actions.updateCrowdsaleState({ cratPrice: logs[0].args?.newPrice?.toString() }));
    },
    eventName: 'PriceUpdate',
    address: wagmiContracts.contracts.Crowdsale.address,
  });
};
