import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useWagmiAuth } from 'app/providers';
import { userModel } from 'entities/user';
import { COLOR_ACCENT, fromDecimals, getToastMessage, Icons, ReferralTip, useShallowSelector } from 'shared';

const constructRefLink = (address: string) => {
  return `${window.location.origin}/${address}`;
};

export const ReferralBlock = () => {
  const { state: authState } = useWagmiAuth();
  const { referralReceived, totalSpend } = useShallowSelector(userModel.selectors.selectUserCrowdsaleInfo);
  const link = constructRefLink(authState.address);

  const handleCopyReferral = () => {
    getToastMessage('success', 'Referral link coppied');
  };

  return (
    <Box py={3}>
      {authState.isAuthorized && Number(totalSpend) !== 0 && (
        <TextField
          value={constructRefLink(authState.address)}
          InputProps={{
            readOnly: true,
            inputProps: { className: 'secondary' },
            endAdornment: (
              <InputAdornment position="end">
                <CopyToClipboard onCopy={handleCopyReferral} text={link}>
                  <IconButton className="transparent">
                    <Icons.Copy
                      sx={{
                        color: COLOR_ACCENT,
                      }}
                    />
                  </IconButton>
                </CopyToClipboard>
              </InputAdornment>
            ),
          }}
          label={
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="inherit" className="secondary">
                Your referral link
              </Typography>
              <ReferralTip />
            </Stack>
          }
          sx={{
            mb: 1,
          }}
        />
      )}
      <Typography component="span" variant="inherit" className="secondary">
        Referral rewards earned:{' '}
        <Typography component="span" variant="inherit">
          {fromDecimals(referralReceived)} USDT
        </Typography>
      </Typography>
    </Box>
  );
};
