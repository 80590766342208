/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Backdrop, ComponentsOverrides, ComponentsProps, Fade, Theme } from '@mui/material';

import { COLOR_BG_BLACK, COLOR_BG_BLACK_4, COLOR_STROKE } from '../colors';
import { BOX_SHADOW_1 } from '../common';

export const getMuiPopoverOverrides = (theme: Theme): ComponentsOverrides['MuiPopover'] => ({
  paper: {
    boxShadow: BOX_SHADOW_1,
    borderRadius: '20px',
    margin: '8px',

    /* targeting select options inside popover */
    '&:has(.MuiList-root .MuiMenuItem-root)': {
      border: '1px solid !important',
      borderColor: `${COLOR_STROKE} !important`,
      backgroundColor: `${COLOR_BG_BLACK} !important`,
      '& .MuiMenuItem-root': {
        color: theme.themeColors.white,
        '&:hover, &.Mui-selected': {
          background: COLOR_BG_BLACK_4,
        },
      },
    },
  },
});

export const getMuiPopoverDefaultProps = (): ComponentsProps['MuiPopover'] => ({
  TransitionComponent: Fade,
  transitionDuration: 650,
  slots: {
    // @ts-ignore
    backdrop: Backdrop,
  },
  slotProps: {
    // @ts-ignore
    backdrop: {
      timeout: 650,
    },
  },
});
