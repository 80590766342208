import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useWagmiAuth } from 'app/providers';
import { ZERO_ADDRESS } from 'shared';
import Web3 from 'web3';
import * as Yup from 'yup';

export interface FormData {
  stablesAmount: string;
  fatherAddress: string;
}

const defaultValues: FormData = {
  stablesAmount: '',
  fatherAddress: '',
};

export const useBuyForm = () => {
  const { state: authState } = useWagmiAuth();

  const validationSchema = Yup.object().shape({
    stablesAmount: Yup.string(),
    fatherAddress: Yup.string().test('Address', 'Invalid address', (val) => {
      if (val === '') {
        return true;
      }

      return (
        !!val &&
        val.toLocaleLowerCase() !== authState.address.toLocaleLowerCase() &&
        val !== ZERO_ADDRESS &&
        Web3.utils.isAddress(val)
      );
    }),
  });

  const form = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(validationSchema) as Resolver<FormData, any>,
    mode: 'onChange',
  });

  return form;
};
