import {
  GemPadIcon,
  GemPadLogo,
  KDGIcon,
  KDGLogo,
  NexusIcon,
  NexusLogo,
  WeLabsIcon,
  WeLabsLogo,
  ZenixIcon,
  ZenixLogo,
} from 'shared';

export const LAUNCHPADS = [
  {
    image: GemPadLogo,
    icon: GemPadIcon,
    title: 'GemPad',
    url: 'https://gempad.app/presale/0xa8c5F2C668d8726c3526fC62BDc0E73bd696dfc5?network=BNB_Smart_Chain',
  },
  {
    image: WeLabsLogo,
    icon: WeLabsIcon,
    title: 'WeLabs',
    url: 'https://welabs.app/projectdetails/48/upcomming',
  },
  {
    image: ZenixLogo,
    icon: ZenixIcon,
    title: 'Zenix',
    url: 'https://www.zenixapp.pro/pools/6m5p19nJRm',
  },
  {
    image: NexusLogo,
    icon: NexusIcon,
    title: 'Nexus',
    url: 'https://shorturl.at/19hsc',
  },
  {
    image: KDGLogo,
    icon: KDGIcon,
    title: 'KDG',
    url: 'https://kingdomstarter.io/launchpad/upcoming/CratD2C',
  },
] as const;

export const LAUNCHPADS_MAP = LAUNCHPADS.reduce<Record<string, (typeof LAUNCHPADS)[number]>>((acc, l) => {
  acc[l.title] = l;

  return acc;
}, {});
